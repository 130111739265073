<template>
  <div v-loading="loading">
    <el-form label-position="right" :model="thoroughlyModuleObj" ref="ruleForm" :rules="rules">
      <!-- <ThoroughlyModuleReceive></ThoroughlyModuleReceive> -->
      <ThoroughlyModuleSendOut :sendOutData="sendOutData" :clearSendOut="clearSendOut" :callModuleTest="callModuleTest"></ThoroughlyModuleSendOut>
      <el-form-item prop="status">
        <el-row>
          <el-col :span="19">
            <el-input
              v-model="thoroughlyModuleObj.status"
              @input="inputValue"
              style="width:550px;"
              size="medium"
              maxlength="30"
              show-word-limit
            ></el-input>
          </el-col>
          <el-col :span="5" align='right'>
            <el-button type="success" @click="thoroughly('ruleForm')" class="devButGreen">发送</el-button>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import ThoroughlyModuleReceive from './thoroughlyModuleReceive'
import ThoroughlyModuleSendOut from './thoroughlyModuleSendOut'
import moment from 'moment'
export default {
  name:'thoroughlyModule',
  props:{
      sn:String|Number,
      mac:String|Number
  },
  components:{
    ThoroughlyModuleReceive,
    ThoroughlyModuleSendOut
  },
  data() {
    return {
      loading:false,
      thoroughlyModuleObj: {
        status: null,
        mac:this.mac,
        sn:this.sn,
      },
      sendOutData:[],
      rules: {
          status: [
            { required: true, message: '请输入透传数据', trigger: 'blur' },
          ],
      }
    };
  },
  methods: {
    //输入限制
    inputValue(value){
      value=value.replace(/[^\w\.\/]/ig,'').toLocaleUpperCase();this.thoroughlyModuleObj.status = value
    },
    //清空
    clearSendOut(){
      if(this.sendOutData.length <= 0){this.$message({type: 'info',message: '没有可清理的数据'});return}
        this.$confirm('此操作将清空发送的数据记录, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
        this.sendOutData=[]
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
    },
    //发送
    thoroughly(formName) {
        this.$refs[formName].validate((valid) => {
            if (valid) {
                if((this.thoroughlyModuleObj.status.length % 2) ){ this.$message.warning("透传数据长度不能是单数！"); return}
                this.thoroughlyModuleObj.status = this.thoroughlyModuleObj.status.toLocaleUpperCase()
                this.callModuleTest(this.thoroughlyModuleObj.status)
            } else {
            return false;
        }
      })
    },
    //调用接口
    callModuleTest(status){
        this.loading = true
        this.$axios({
            method:'get',
            url:'/common2/devices/ModuleTest',
            params:{
              status,mac:this.thoroughlyModuleObj.mac,sn:this.thoroughlyModuleObj.sn
            }
        }).then(res=>{
            this.loading = false
            let result = JSON.parse(res.data.result)
            if(result.code === 1){
              this.$message.warning(`发送${result.msg}`)
              return
            }
            let date = moment().format('HH:mm:ss.SSS');
            this.sendOutData.unshift({
             date, 
             status,
            })
            this.$message.success(`发送${result.msg}`)
        }).catch(err=>{
            this.loading = false
            this.isError(err,this)
        })
    }
  },
};
</script>

<style lang="scss" scoped>
@import '../devButStyle.scss';
</style>
<style>

</style>