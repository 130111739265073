<template>
    <div id="box">
        <el-row class="title">
            <el-col :span="12" align="left">
                收：
            </el-col>
            <el-col :span="12" align="right"  class="clear">
                <el-link type="danger" @click="clearReceive">清空</el-link>
            </el-col>
        </el-row>
        <div class="content">
            <p class="title border" v-for="(item,index) in receiveData" :key="index">
                <span class="time">[{{item.date}}]收：</span>
                <span class="data">{{ item.status }}</span>
            </p>
        </div>

    </div>
</template>

<script>
export default {
  data() {
    return {
        receiveData:[]
    };
  },
  methods: {
      clearReceive(){
          this.receiveData = []
      }
  },
};
</script>

<style lang="scss" scoped>
@import './thoroughlyModuleCSS';
</style>
