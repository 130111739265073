<template>
    <div id="box">
        <el-row class="title">
            <el-col :span="12" align="left">
                发：
            </el-col>
            <el-col :span="12" align="right" class="clear">
                <el-link type="danger" @click="clearSendOut">清空</el-link>
            </el-col>
        </el-row>
        <div class="content1">
            <p class="title border" v-for="(item,index) in sendOutData" :key="index">
                <span class="time">[{{item.date}}] 发 ：</span>
                <span class="data">{{ item.status }}</span>
                <i class="el-icon-refresh-right" @click="retransmission(item)"></i>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        sendOutData:Array,
        clearSendOut:Function,
        callModuleTest:Function,
    },
    watch:{
        'sendOutData.length':{
            handler: function(val, oldVal) {
                if(val != 0){
                    this.sendOutData[0].status = this.sendOutData[0].status.replace(/\s/g,'').replace(/(.{2})/g,"$1\u3000");
                }
            },
        }
    },
    data() {
        return {
        }
    },
    created(){
    },
    methods: {
        retransmission(row){
            let status = row.status.split('\u3000').join('')
            console.log(status)
            this.callModuleTest(status)
        }
    },
}
</script>

<style lang="scss" scoped>
@import './thoroughlyModuleCSS';

</style>